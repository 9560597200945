import React, { useEffect, useContext, useState } from "react";
import { Box, Flex, Text, TransparentButton, Divider } from "flicket-ui";
import { useRouter } from "next/router";

import { Icon, ContentModal } from "~components";

import { BaseContext } from "~context";
import { Layout } from "~components/common/Layout";
import { EventQuery } from "~graphql/sdk";

import { Desktop } from "./detail.Desktop";
import { Mobile } from "./detail.Mobile";
import { useIsMobile } from "~hooks";

export type DetailsProps = {
  event: EventQuery["event"];
  releaseIsActive?: boolean;
};

export const Detail = ({ event, releaseIsActive }: DetailsProps) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const { isSinglePage } = useContext(BaseContext);
  const [isOpen, setIsOpen] = useState(false);
  const cartRecovery = !!router.query.cartRecovery;

  const navigateReservation = (cartRecovery?: boolean) => {
    if (cartRecovery) {
      void router.push(
        "/events/[eventId]/reservation?cartRecovery=true",
        `/events/${event?.id}/reservation?cartRecovery=true`
      );
    } else {
      void router.push(
        "/events/[eventId]/reservation",
        `/events/${event?.id}/reservation`
      );
    }
  };

  useEffect(() => {
    if (cartRecovery) {
      void router.prefetch(
        "/events/[eventId]/reservation?cartRecovery=true",
        `/events/${event?.id}/reservation?cartRecovery=true`
      );
    } else {
      void router.prefetch(
        "/events/[eventId]/reservation",
        `/events/${event?.id}/reservation`
      );
    }
  }, [event?.id]);

  return (
    <>
      {isMobile && (
        <Box
          d={{ _: "flex", md: "none" }}
          flexDirection="column"
          flex={1}
          pt={isSinglePage ? 8 : 0}
        >
          {!isSinglePage && (
            <>
              <Flex px={2} py={3} justifyContent="space-between">
                <Text
                  lineHeight="normal"
                  fontSize={6}
                  fontWeight="extraBold"
                  color="N800"
                >
                  Event Information
                </Text>
                <TransparentButton href="/events">
                  <Icon icon="close" fontSize={6} color="N800" />
                </TransparentButton>
              </Flex>
              <Divider />
            </>
          )}
          <Layout pt={{ _: "0 !important" as any, md: 8 }} layoutType="sub">
            <Mobile
              {...event}
              bannerImage={event?.logo}
              setIsOpen={setIsOpen}
              navigate={navigateReservation}
              showSeatsCTA={releaseIsActive ?? event?.hasPublicRelease}
            />
          </Layout>
        </Box>
      )}
      {!isMobile && (
        <Box d={{ _: "none", md: "flex" }} flexDirection="column" flex={1}>
          <Layout layoutType="main">
            <Desktop
              {...event}
              setIsOpen={setIsOpen}
              showSeatsCTA={releaseIsActive ?? event?.hasPublicRelease}
              isSinglePage={isSinglePage}
            />
          </Layout>
        </Box>
      )}
      <ContentModal
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        title="Accessibility"
        richText={event?.accessibility}
      />
    </>
  );
};
