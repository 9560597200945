/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Box, Flex, Text, Link, SystemProps } from "flicket-ui";
import { Icon } from "~components";
import React from "react";
import { DesktopDetailProps } from "./Detail/detail.Desktop";
import { formatDate } from "~lib";

type EventTitleProps = Pick<
  DesktopDetailProps,
  "isSinglePage" | "title" | "startDate" | "endDate"
> & {
  hideBackLink?: boolean;
  backToLinkText?: string;
  backToLink?: string;
  venueName?: string;
  city?: string;
  tourId?: string;
  colour?: SystemProps["color"];
};

export const EventTitle = ({
  isSinglePage,
  backToLinkText = "Back to Events",
  backToLink = "/",
  hideBackLink = false,
  title,
  startDate,
  endDate,
  venueName,
  city,
  tourId,
  colour = "white",
}: EventTitleProps) => (
  <Box width="100%" maxWidth="1248px" pl={4} mx={"auto" as any} mb={1}>
    {hideBackLink ? null : (
      <Link to={tourId ? `/tours/${tourId}` : backToLink}>
        <Flex alignItems="center" opacity="inherit">
          <Icon icon="chevron-left" fontSize={5} color={colour} />
          {isSinglePage ? (
            <Text
              as={"h2"}
              color={colour}
              fontWeight="extraBold"
              fontSize={3}
              lineHeight="medium"
              ml="3/4"
            >
              Back to ticket selection
            </Text>
          ) : (
            <Text
              color={colour}
              fontWeight="extraBold"
              fontSize={3}
              lineHeight="medium"
              ml="3/4"
            >
              {backToLinkText}
            </Text>
          )}
        </Flex>
      </Link>
    )}
    <Text
      as={"h1"}
      color={colour}
      fontWeight="heavy"
      fontSize={9}
      lineHeight={"56px" as any}
      mt={1}
    >
      {title}
    </Text>
    <Text
      color={colour}
      fontWeight="heavy"
      fontSize={5}
      lineHeight={"95%" as any}
      mb={1}
    >
      {endDate && formatDate(startDate, "dd") !== formatDate(endDate, "dd")
        ? `${formatDate(startDate, "dd LLL")} - ${formatDate(
            endDate,
            "dd LLL yyyy"
          )}`
        : formatDate(startDate, "E, dd LLL yyyy p")}
      &nbsp;
      {!!venueName && (
        <Box as="span" d={{ _: "none", md: "inline-block" }}>
          - {`${venueName}, ${city ? city : ""}`}
        </Box>
      )}
    </Text>
  </Box>
);
